<template>
  <div class="product-components-skylight-lamp-wrapper">
    <!-- <div class="title">
      {{ $t('EWINDOW') }}
    </div> -->
    <div
      v-if="isLoad"
      class="lamp-list"
    >
      <template
        v-for="(item, index) in list"
      >
        <div
          :key="index"
          v-if="index === 0"
          class="list-warpper list-wrapper-100"
        >
          <div class="lamp-left">
            <div>
              <div>{{ item.title }}</div>
              <div>{{ item.desction }}</div>
            </div>
            <div
              class="learn-more"
              @click="getProduct(item)"
            >
              {{ $t('learnMore') }}
            </div>
          </div>
          <div class="lamp-right">
            <img :src="cover(item)" />
          </div>
        </div>
        <div
          :key="index"
          class="list-warpper"
          v-else
        >
          <div class="lamp-top">
            <img :src="cover(item)" />
          </div>
          <div class="lamp-bottom">
            <div>
              <div>{{ item.title }}</div>
              <div>{{ item.desction }}</div>
            </div>
            <div
              class="learn-more"
              @click="getProduct(item)"
            >
              {{ $t('learnMore') }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { goGet } from '@/utils'
export default {
  name: 'ProductLLamp',
  metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  data() {
    return {
      isLoad: false,
      productSkylight: []
    }
  },

  mounted() {
    this.getList()
  },

  computed: {
    cover() {
      return (item) => {
        return  process.env.VUE_APP_API + item.cover.split(',')[0]
      }
    },

    list() {
      return this.productSkylight.filter(list => list.lang === this.$i18n.locale)
    }
  },

  methods: {
    getList() {
      goGet('/ewinlight/product/list').then(res => {
        this.isLoad = true
        this.productSkylight = res.data
      })
    },
    getProduct(item) {
       this.$router.push('/product/details/' + item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-components-skylight-lamp-wrapper {
  background: rgba(245, 246, 247, 1);
  overflow: hidden;
  .title {
    font-size: 32px;
    padding: 0;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .lamp-list {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 140px;
    margin-top: 80px;
    .list-warpper {
      width: 49%;
      height: 705px;
      padding: 35px;
      background: #fff;
      margin-bottom: 20px;
      &.list-wrapper-100 {
        width: 100%;
        height: 433px;
        padding: 35px 55px;
        display: flex;
        justify-content: space-between;
        padding-left: 110px;
        .lamp-left {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 67px;
          padding-bottom: 37px;
          &>div:nth-child(1) {
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            line-height: 26px;
            &>:nth-child(1) {
              color: rgba(34, 34, 34, 1);
              font-weight: bold;
              font-size: 30px;
              margin-bottom: 10px;
              line-height: 54px;
            }
          }
        }
        .lamp-right {
          width: 43%;
          height: 363px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .lamp-top {
        width: 100%;
        height: 363px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .lamp-bottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100% - 363px);
        width: 100%;
        padding-top: 40px;
        &>div:nth-child(1) {
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          line-height: 26px;
          width: 80%;
          &>:nth-child(1) {
            color: rgba(34, 34, 34, 1);
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 10px;
            line-height: 54px;
          }
        }
      }
    }
  }
  .learn-more {
    width: 109px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    cursor: pointer;
    background: rgba(51, 51, 51, 1);
  }
}
</style>